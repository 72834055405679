import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import tokenabi from 'config/abi/cake.json'
import routerabi from './routerabi.json'
/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */

const useGetPriceDataCustom = async (token) => {

    try{
        console.log("FIRED")
        const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');
        const contract = new ethers.Contract(token, tokenabi, provider)
        const decimals = await contract.decimals() 
        const amount = 1 * 10 ** decimals;

        // get price in BUSD
        const contract2 = new ethers.Contract('0x7a250d5630b4cf539739df2c5dacb4c659f2488d', routerabi, provider)
        let amountsOut = await contract2.getAmountsOut(amount.toString(), [token,'0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2','0xdac17f958d2ee523a2206206994597c13d831ec7'])
        console.log('amounts out is "', amountsOut[2].toString())
        amountsOut = amountsOut[2].toString() / 10 ** 18;
        
        return amountsOut;
    }catch(err){
        console.log(err)
        return "0";
    }

}

export default useGetPriceDataCustom
