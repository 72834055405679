import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    18,
    'WETH',
    'Wrapped ETH',
    'https://www.ethereum.org/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', 18, 'ETH', 'ETH', 'https://www.etheruem.org/'),
  // wrappedkishimoto: new Token(MAINNET, '0xf5bfBCd48E39cbf01Ee18A55D34E4ec42A17F483', 18, 'MGC', 'WRAPPED KISHIMOTO', 'https://multigencapital.io/'),
  cake: new Token(MAINNET, '0xf5bfBCd48E39cbf01Ee18A55D34E4ec42A17F483', 18, 'WKISHIMOTO', 'WRAPPED KISHIMOTO', 'https://kishimotoinu.com'),
  busd: new Token(MAINNET, '0x4fabb145d64652a948d72533023f6e7a623c7c53', 18, 'BUSD', 'BUSD', 'https://paxos.com/busd'),
  kishimoto: new Token(MAINNET, '0xf5b1fd29d23e98db2d9ebb8435e1082e3b38fb65',9, 'KISHIMOTO', 'Kishimoto Inu', 'https://kishimotoinu.com/'),
  saitamainu: new Token(MAINNET, '0x8b3192f5eebd8579568a2ed41e6feb402f93f73f',9, 'SAITAMA', 'Saitama Inu', 'https://saitamatoken.com/'),
  wrappedkishimoto: new Token(MAINNET, '0xf5bfBCd48E39cbf01Ee18A55D34E4ec42A17F483',18, 'WKISHIMOTO', 'Wrapped Kishimoto', 'https://kishimotoinu.com/'),
  loot: new Token(MAINNET, '0xe4129C7B229812212F88d1BD6a223C45622E6b85',9, 'LOOT', 'Looted Network', 'https://looted.net/'),
  lootunlocked: new Token(MAINNET, '0xe4129C7B229812212F88d1BD6a223C45622E6b85',9, 'LOOT (90 days unlocked)', 'Looted Network', 'https://looted.net/'),
  lootlocked: new Token(MAINNET, '0xe4129C7B229812212F88d1BD6a223C45622E6b85',9, 'LOOT (30 days locked)', 'Looted Network', 'https://looted.net/')

}


export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  cake: new Token(
    TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
