/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
// eslint-disable-next-line import/no-unresolved

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

const Header = () => {
  return (
    <Fade top duration={1000} distance="20px">
      <div>
        <header className="header">
          <a href="/">
            <div
              style={{
                justifyContent: "left",
                display: "flex",
                alignItems: "left",
              }}
            >
              <img src='/logo.svg' alt="data" style={{ width: "200px", marginLeft:'2rem' }} />

            </div>

            
          </a>

        </header>
      </div>
    </Fade>
  );
};
export default Header;
